export const environment = {
  production: true,
  apiBaseUrl: "https://api.dev.ogarni.ai",
  insights: {
    instrumentationKey: "32c867e1-76ba-433e-8cac-a598b6f1201c"
  },
  entra: {
    clientId: "57b162c4-ab2c-47a0-a498-76c501d462ec",
    authorityUrl: "https://ogarniaiauth.ciamlogin.com/ogarniaiauth.onmicrosoft.com",
    scopes: [
      "api://84f78e03-f479-4875-9473-c3c02bcd30b5/access_as_user"
    ]
  }
};
